<template>
    <div class="w-full flex flex-col md:flex-row">
        <ConversationInterface 
            :conversations="conversations"
            withName="Support"
            mode="mapi"
            :moreToLoad="loadMoreUrl.length > 0"
            @refreshConversations="refreshConversations()"
            @viewOpen="viewOpen()"
            @viewClosed="viewClosed()"
            @loadMoreConversations="(e) => {loadMoreConversations(e)}"
            @postNewConversation="(e) => {postNewConversation(e)}" />
    </div>
</template>

<script>

    import { ref } from 'vue'
    import { useStore } from 'vuex'

    import Icon from '@/components/Icon.vue'
    import ConversationInterface from '@/components/ConversationInterface.vue'

    export default {
        name: 'Secure Messaging',
        components: {
            ConversationInterface,
            Icon 
        },
        methods: {
            async loadMoreConversations(){
                console.log('load more conversations: ', this.loadMoreUrl);
                await this.loadConversations(this.loadMoreUrl);
            },
            async loadConversations(url){
                console.log('loadConversations');

                if(url == undefined)
                    url = 'conversations?page[size]=10';
                
                if(this.viewingClosed == true)
                    url += '&filter[status]=closed';

                let fetched = await this.$mapi.get(url);
                let convos = {};
                console.log(fetched.data);
                for(var ii = 0; ii < fetched.data.data.length; ii++){
                    let convo = fetched.data.data[ii];
                    convos[convo.id] = convo;
                }
                this.conversations = Object.assign({},this.conversations,convos);

                if(fetched.data.links != undefined && fetched.data.links.next != undefined){
                    this.loadMoreUrl = fetched.data.links.next
                }else{
                    this.loadMoreUrl = '';
                }
                console.log('conversations: ', this.conversations);
            },
            async postNewConversation(e){
                console.log('e: ', e);
                await this.$mapi.post("conversations", {
                    "data": {
                        "attributes": {
                        "subject": e.subject,
                        "body": e.body
                        }
                    }
                });
                this.loadConversations();
            },
            viewClosed(){
                this.viewingClosed = true;
                this.conversations = [];
                this.loadConversations();
            },
            viewOpen(){
                this.viewingClosed = false;
                this.conversations = [];
                this.loadConversations();
            },
            refreshConversations(){
                this.conversations = [];
                this.loadConversations();
            }
        },
        created(){
            document.title="Secure Messaging";
            this.loadConversations();
        },
		async mounted(){
            this.scrollToBottom();
        },
        setup(){
            
            let store = useStore();
            const loadMoreUrl = ref('');
            const msgBox = ref(null);

            const conversations = ref({});
            const viewingClosed = ref(false)

            const convTitle = ref('Credit Card Charge');
 
            let prevMsg = undefined;
            function msgOuterClass(msg){ 
                let classes = ' ';

                if(!msg.fromSelf)
                    classes += 'justify-start ';
                else
                    classes += 'justify-end ';

                if(prevMsg !== undefined)
                if(msg.fromSelf != prevMsg.fromSelf){
                    classes += 'mt-5 '
                }

                return classes;
            }

            function loadConvo(name){
                console.log('loadConvo');
            }

            function msgInnerClass(msg){
                let classes = ' ';
   
                if(!msg.fromSelf)
                    classes += 'bg-white ';
                else
                    classes += 'bg-blue-100 ';

                return classes;
            }

            function msgFromClass(msg){
                let classes = ' ';

                if(prevMsg !== undefined)
                if(msg.fromSelf ||
                    (msg.fromSelf === prevMsg.fromSelf && msg.time === prevMsg.time))
                    classes += 'hidden ' 

                return classes;
            }

            // Called last, so updates prevMsg to msg
            function msgTimeClass(msg){
                let classes = ' ';

                if(prevMsg !== undefined)
                    if(msg.time === prevMsg.time && msg.fromSelf === prevMsg.fromSelf)
                        classes += 'hidden '

                prevMsg = msg;
                return classes;
            }

            function scrollToBottom(){            
                if(msgBox.value !== null){
                    msgBox.value.scroll(0,msgBox.value.offsetHeight)
                }
            }


        return{
            conversations,
            convTitle,
            loadConvo,
                loadMoreUrl,
            msgBox,
            msgFromClass,
            msgInnerClass,
            msgOuterClass,
            msgTimeClass,
            scrollToBottom,
            store,
            viewingClosed
        }    
      }
    }
</script>

<style scoped>
    .chat-height{
        max-height: 65vh;
    }
</style>