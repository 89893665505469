<template>
    <div class="w-full flex flex-col md:flex-row">
        <ConversationInterface 
            :conversations="conversations"
            mode="fapi"
            :moreToLoad="loadMoreUrl.length > 0"
            :showNewConversation="true"
            @refreshConversations="refreshConversations()"
            @viewOpen="viewOpen()"
            @viewClosed="viewClosed()"
            @loadMoreConversations="(e) => {loadMoreConversations(e)}"
            @postNewConversation="(e) => {postNewConversation(e)}" />
    </div>
</template>

<script>
    import { ref } from 'vue'

	import ConversationInterface from "@/components/ConversationInterface.vue";
	import Chat from "@/components/Chat.vue";
	import Form from "@/components/Form.vue";
	import Icon from "@/components/Icon.vue";
	import Field from "@/components/Field.vue";
    import { useRouter } from "vue-router"

	export default {
		components:{
            Chat,
            ConversationInterface,
			Form,
			Field,
            Icon
		},
		data(){
			return {
			};
		},
        methods: {
            async loadMoreConversations(){
                console.log('load more conversations: ', this.loadMoreUrl);
                await this.loadConversations(this.loadMoreUrl);
            },
            async loadConversations(url){
                console.log('loadConversations');

                if(url == undefined)
                    url = 'messaging/conversations?page[size]=10';

                if(this.viewingClosed == true)
                    url += '&filter[status]=closed';

                

                let fetched = await this.$fapi.get(url);
                let convos = {};
                console.log('fetched.data: ', fetched.data);
                for(var ii = 0; ii < fetched.data.data.length; ii++){
                    let convo = fetched.data.data[ii];
                    convos[convo.id] = convo;
                }
                
                this.conversations = Object.assign({},this.conversations,convos);

                if(fetched.data.links != undefined && fetched.data.links.next != undefined){
                    this.loadMoreUrl = fetched.data.links.next
                }else{
                    this.loadMoreUrl = '';
                }
                console.log('conversations: ', this.conversations);
            },            
            async postNewConversation(e){
                console.log('admin.postNewConversation: ', e);
                
                await this.$fapi.post("messaging/conversations", {
                    "data": {
                        "attributes": {
                            "subject": e.subject,
                            "body": e.body,
                            "account": e.account
                        }
                    }
                });
                
                //Slight delay before reloading conversations, otherwise new item is at bottom instead of top                
                await new Promise(resolve => setTimeout(resolve, 100));  
                this.refreshConversations();
            },
            openChat(id){
                this.focusedId = id;
                this.chatOpen=true;
                this.focusedMode='';                
            },
            viewClosed(){
                this.viewingClosed = true;
                this.conversations = [];
                this.loadConversations();
            },
            viewOpen(){
                this.viewingClosed = false;
                this.conversations = [];
                this.loadConversations();
            },
            refreshConversations(){
                this.conversations = [];
                this.loadConversations();
            }
        },
        computed: {
            conversationsToShow(){

                let allMemb = [];

                if(this.viewUnread == true){
                    for(var ii = 0; ii < this.members.length; ii++){
                        let memb = this.members[ii];
                        if(memb.unread != undefined){
                            allMemb.push(memb);
                        }
                    }
                }else{
                    allMemb = this.members
                }

                if(this.quickFilterVal == '')
                    return allMemb;
                else{
                    this.focusedId = -1;
                    let needle = this.quickFilterVal.toLowerCase();
                    let conversationsToShow = [];

                    console.log('needle: ', needle)

                    for(var ii = 0; ii < allMemb.length; ii++){

                        let memb = allMemb[ii];
                        console.log('memb: ', memb);
                        if(memb.name.toLowerCase().indexOf(needle) != -1
                        ||memb.uname.toLowerCase().indexOf(needle) != -1
                        ||memb.email.toLowerCase().indexOf(needle) != -1
                        ||memb.phone.indexOf(needle) != -1
                        ||memb.phone.replace(/-/g, "").indexOf(needle) != -1)
                            conversationsToShow.push(memb);

                    }

                    return conversationsToShow;

                }
            }
        },
		created(){
            document.title = 'Secure Messaging'

            this.loadConversations();
		}, 

        setup(){
            const viewMode = ref("large")
            const focusedMode = ref("");
            const chatOpen = ref(false);
            const focusedId = ref(-1)
            const quickFilterVal = ref("")
            const viewUnread = ref(false)
            const loadMoreUrl = ref('');
            const viewingClosed = ref(false);
            const members = ref([
                {
                    id: 1,
                    name: 'George Costanza',
                    uname: 'GeoCost',
                    email: 'George@email.com',
                    phone: '262-727-8282',
                    awaitingReply: true,
                    lastChat: 'Login Issues',
                    lastResponse: '08/12/22 08:55AM',
                    lastMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna',
                    unread: 5,
                    messages:[
                    { key: 0, time: '10/07/21 10:04 AM', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna, convallis ac purus id, tempus tempor leo. Aenean id nisl ligula. Etiam ornare tempus tellus a bibendum.', fromSelf: false },
                    { key: 1, time: '10/07/21 10:04 AM', text: 'Mauris maximus nisi nisi, in finibus lorem dignissim vehicula.', fromSelf: true  },
                    { key: 2, time: '10/07/21 10:05 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: true  },
                    { key: 3, time: '10/07/21 10:05 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                    { key: 4, time: '10/07/21 10:06 AM', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna, convallis ac purus id, tempus tempor leo. Aenean id nisl ligula. Etiam ornare tempus tellus a bibendum.', fromSelf: false },
                    { key: 5, time: '10/07/21 10:06 AM', text: 'Mauris maximus nisi nisi, in finibus lorem dignissim vehicula.', fromSelf: true  },
                    { key: 6, time: '10/07/21 10:06 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: true  },
                    { key: 7, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                    { key: 8, time: '10/07/21 10:10 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: false  },
                    { key: 9, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                        
                    ]
                },
                {
                    id: 2,
                    name: 'Jerry Seinfeld',
                    uname: 'JerSein',
                    email: 'Jerry@email.com',
                    phone: '443-444-4444',
                    notice: 'Misc account notice',
                    lastChat: 'Closing Account',
                    lastMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna',
                    lastResponse: '08/11/22 04:30PM',
                },
                {
                    id: 3,
                    name: 'Elaine Bennet',
                    uname: 'ElaBnt',
                    email: 'Elain@email.com',
                    phone: '443-444-4444',
                    awaitingReply: true,
                    lastMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna',
                    lastChat: 'Loan Inquiry',
                    lastResponse: '08/10/22 10:15AM',
                }
            ])

            const activeConvo = ref(1);
            const convos = ref([
                {
                    id: 1,
                    title: 'Login issue 3',
                    lastActivity: '08/07/2022',
                    awaitingReply: true
                },
                {
                    id: 2,
                    title: 'Login issue 2',
                    lastActivity: '08/04/2022',
                    awaitingReply: false
                },
                {
                    id: 3,
                    title: 'Login issue',
                    lastActivity: '08/01/2022',
                    awaitingReply: true
                }
            ]);
            const conversations = ref([]);

            function loginAsUser(){
                window.open('')
            }

            function toggleViewUnread(){
                viewUnread.value =  !viewUnread.value;
            }

            return { 
                loginAsUser,
                chatOpen,
                conversations,
                quickFilterVal,
                focusedId,
                focusedMode,
                convos,
                activeConvo,
                members,
                loadMoreUrl,
                viewingClosed,
                viewMode,
                viewUnread,
                toggleViewUnread

             }

        }
	}
	
</script>
